<template>
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <div class="row">
      <div class="document-item">
        <div
          v-on:click="
            $router
              .push('/documents/final_will/' + document.id)
              .catch((error) => {
                console.log(error)
              })
          "
          class="document-image"
        >
          <div class="document-image-holder">
            <img alt="my will document" :src="document.image" />

            <div class="animated-tick">
              <div class="trigger drawn">
                <svg
                  id="tick"
                  style="enable-background: new 0 0 37 37"
                  version="1.1"
                  viewbox="0 0 37 37"
                  x="0px"
                  xmlns="http://www.w3.org/2000/svg"
                  y="0px"
                >
                  <path
                    class="circ path"
                    d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                    style="
                      fill: none;
                      stroke: #66ab8c;
                      stroke-width: 2px;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    "
                  ></path>
                  <polyline
                    class="tick path"
                    points="11.6,20 15.9,24.2 26.4,13.8"
                    style="
                      fill: none;
                      stroke: #66ab8c;
                      stroke-width: 2px;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    "
                  ></polyline>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="document-options">
          <p class="document-created-date">
            Created:
            <span>{{ formatDateAndTime(document.uploaded_at) }}</span>
          </p>

          <p v-if="document.printing_required" class="document-created-date">
            Printed & sent: <span v-if="document.printed">Yes</span
            ><span v-else>No</span>
          </p>

          <a
            class="document-checked-date"
            target="_blank"
            :href="pdfDownloadURI"
            >Download <i class="fa fa-download"></i
          ></a>
          <a
            v-if="document.in_storage && document.storage_certificate_pdf"
            class="document-checked-date"
            target="_blank"
            :href="pdfDownloadStorageURI"
            >In Storage, Certificate <i class="fa fa-download"></i
          ></a>
          <p v-if="document.edit_notes" class="document-checked-date">
            Notes: {{ document.edit_notes }}
          </p>
          <a
            v-if="latest"
            v-on:click="
              $router.push('/home').catch((error) => {
                console.log(error)
              })
            "
            class="document-created-date"
            href="#"
            >Edit this Will</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'WillSprite',
  mixins: [TextFormat],
  props: ['document', 'latest'],
  computed: {
    pdfDownloadURI() {
      if (this.document.combined_pdf) return this.document.combined_pdf
      else if (this.document.pdf) return this.document.pdf
      return ''
    },
    pdfDownloadStorageURI() {
      if (this.document.storage_certificate_pdf)
        return this.document.storage_certificate_pdf
      return ''
    }
  }
}
</script>
