var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"document-item"},[_c('div',{staticClass:"document-image",on:{"click":function($event){_vm.$router
            .push('/documents/final_will/' + _vm.document.id)
            .catch((error) => {
              _vm.console.log(error)
            })}}},[_c('div',{staticClass:"document-image-holder"},[_c('img',{attrs:{"alt":"my will document","src":_vm.document.image}}),_c('div',{staticClass:"animated-tick"},[_c('div',{staticClass:"trigger drawn"},[_c('svg',{staticStyle:{"enable-background":"new 0 0 37 37"},attrs:{"id":"tick","version":"1.1","viewbox":"0 0 37 37","x":"0px","xmlns":"http://www.w3.org/2000/svg","y":"0px"}},[_c('path',{staticClass:"circ path",staticStyle:{"fill":"none","stroke":"#66ab8c","stroke-width":"2px","stroke-linejoin":"round","stroke-miterlimit":"10"},attrs:{"d":"M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"}}),_c('polyline',{staticClass:"tick path",staticStyle:{"fill":"none","stroke":"#66ab8c","stroke-width":"2px","stroke-linejoin":"round","stroke-miterlimit":"10"},attrs:{"points":"11.6,20 15.9,24.2 26.4,13.8"}})])])])])]),_c('div',{staticClass:"document-options"},[_c('p',{staticClass:"document-created-date"},[_vm._v(" Created: "),_c('span',[_vm._v(_vm._s(_vm.formatDateAndTime(_vm.document.uploaded_at)))])]),(_vm.document.printing_required)?_c('p',{staticClass:"document-created-date"},[_vm._v(" Printed & sent: "),(_vm.document.printed)?_c('span',[_vm._v("Yes")]):_c('span',[_vm._v("No")])]):_vm._e(),_c('a',{staticClass:"document-checked-date",attrs:{"target":"_blank","href":_vm.pdfDownloadURI}},[_vm._v("Download "),_c('i',{staticClass:"fa fa-download"})]),(_vm.document.in_storage && _vm.document.storage_certificate_pdf)?_c('a',{staticClass:"document-checked-date",attrs:{"target":"_blank","href":_vm.pdfDownloadStorageURI}},[_vm._v("In Storage, Certificate "),_c('i',{staticClass:"fa fa-download"})]):_vm._e(),(_vm.document.edit_notes)?_c('p',{staticClass:"document-checked-date"},[_vm._v(" Notes: "+_vm._s(_vm.document.edit_notes)+" ")]):_vm._e(),(_vm.latest)?_c('a',{staticClass:"document-created-date",attrs:{"href":"#"},on:{"click":function($event){_vm.$router.push('/home').catch((error) => {
              _vm.console.log(error)
            })}}},[_vm._v("Edit this Will")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }