<template>
  <NavQuestion
    :heading="'Your Documents'"
    :questionOptions="false"
    v-bind:background="'bg-people-manager'"
  >
    <template v-slot:contents>
      <WillSprite
        v-for="(document, index) in checkedDocuments"
        :key="index"
        :document="document"
        :latest="index === 0"
      ></WillSprite>
      <p v-if="checkedDocuments.length === 0">
        You have not yet completed your Will.
      </p>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import WillSprite from './WillSprite'

export default {
  name: 'DocumentsPage',
  components: {
    NavQuestion,
    WillSprite
  },
  computed: {
    documents() {
      return this.$store.getters.documents
    },
    checkedDocuments() {
      var docs = this.documents.filter((document) => document.checked)
      return docs.sort(
        (a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at)
      )
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
